<!-- Navigation-->
<nav class="navbar navbar-expand-lg navbar-light bg-light mb-3">
  <span class="navbar-brand"><a [routerLink]="'/'">
    <span *ngIf="!config.showJustImage" >{{title}}</span>
    <img *ngIf="config.showJustImage" style="height: 128px;" src="{{config.image}}" alt="..." />
  </a>
  </span>
  <span *ngIf="config.showButton" style="padding: 0.5em;">
    <a class="btn btn-primary js-scroll-trigger" target="_blank" href="{{config.buttonUrl}}">{{config.buttonText}}</a>
  </span>
  <!-- Step 3: Toggle the value of the property when the toggler button is clicked. -->
  <button class="navbar-toggler" type="button" (click)="isMenuCollapsed = !isMenuCollapsed">
    &#9776;
  </button>

  <!-- Step 2: Add the ngbCollapse directive to the element below. -->
  <div [ngbCollapse]="isMenuCollapsed" class="collapse navbar-collapse" i18n="@@app_header_navigation_links">
    <ul class="navbar-nav mx-auto sm-icons mr-0">
      <li *ngFor="let sm of social_media" class="nav-item">
        <a class="nav-link" target="_blank" href="{{sm.link}}"><i class="{{sm.class}}  fa-2x"></i></a>
      </li>
    </ul>
    <ul class="navbar-nav ml-auto">
      <!-- Step 4: Close the menu when a link is clicked. -->
      <li  *ngFor="let nav of navs" class="nav-item">
        <a *ngIf="nav.isRoute" class="nav-link" [routerLink]="'/home'" fragment="{{nav.fragment}}" (click)="isMenuCollapsed = true" routerLinkActive="activebutton">{{nav.text}}</a>
        <a *ngIf="!nav.isRoute" class="nav-link" target="_blank" href="{{nav.url}}" (click)="isMenuCollapsed = true">{{nav.text}}</a>
      </li>
    </ul>
    
  </div>
</nav>

<router-outlet></router-outlet>

<!-- Footer-->
<footer class="footer bg-black small text-center text-white-50"><div class="container">This website was created and hosted by <a href="http://www.ahostingsolutions.com" target="_blank">AHosting Solutions.</a></div></footer>

