import { Component } from '@angular/core';
import { config } from './config/grayscale/config';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  config = config;
  navs = config.navigationLinks;
  title = config.title;
  social_media = config.section6.social_media;
  public isMenuCollapsed = true;
  public constructor(private titleService: Title) { 
    titleService.setTitle(config.title);
  }
}
