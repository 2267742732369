export const config = {
    showJustImage:true,
    title: "philip4mayor",
    image: "assets/img.philip4mayor.com/philip4mayorclear320x320.png",
    showButton:true,
    buttonText:"Donate",
    buttonUrl:"https://secure.anedot.com/philip-molestina-for-mayor/26f4069f-af57-48ab-ac34-b9c965c158d6",
    navigationLinks:[
        { 
            routerLink:"home",
            fragment:"section1",
            text:"Home",
            isRoute:true
        },
        { 
            routerLink:"",
            fragment:"",
            text:"Bio",
            url:"assets/img.philip4mayor.com/bio.pdf",
            isRoute:false
        },
        { 
            routerLink:"",
            fragment:"",
            text:"Platform",
            url:"assets/img.philip4mayor.com/platform.pdf",
            isRoute:false
        },
        { 
            routerLink:"home",
            fragment:"section3",
            text:"About Philip",
            isRoute:true
        },
        { 
            routerLink:"home",
            fragment:"section4_5",
            text:"Contact Us",
            isRoute:true
        }
    ],
    section1:{
        line1: "",
        line2: "",
        line3: "Donate",
        fragment:"section3_line2",
        showButtons:false
    },
    section2:{
        line1: "Announcement Video ",
        line2: 
        {
            sublines:[
                {text: "Philip for Mayor of Louisville."}
            ]
        },
        img: "assets/img.philip4mayor.com/section.2.jpg"
    },
    section3: {
        line1:{ 
            title:"About Philip",
            sublines:[
                {text: "Pastor and founder of He Visto la Luz Christian Church since 2001 - assisting families in the community"},
                {text: "20-plus year business management of local owned companies."},
                {text: "Norton Healthcare Board Member; Faith & Health Ministries, since 2014"},
                {text: "Graduate of University of Louisville Business School"},
                {text: "Philip has resided in Louisville for 41 years. Together with his wife Norma they have three adult children"}
            ],
            img: "assets/img.philip4mayor.com/section.3.1.jpg"
        },
        projects: [
            {
                title: "Opportunity to make a change",
                text: "Once a useful building in Louisville it is now closed. If the correct changes are made it can be useful again. Louisville has several things it needs to change so many people can experience new opportunities.",
                img: "assets/img.philip4mayor.com/section.3.2.png",
                class: ""
            },
            {
                title: "Leadership",
                text: "Leadership not politics for the city of Louisville",
                img: "assets/img.philip4mayor.com/section.3.3.jpg",
                class: "order-lg-first"
            }
        ],
        line2:{ 
            show:false,
            title:"Donate",
            text: "Help us get our message of leadership to the voters by making a contribution to the campaign.",
            url:"https://secure.anedot.com/philip-molestina-for-mayor/26f4069f-af57-48ab-ac34-b9c965c158d6?embed=true"
        },
    },
    section4_5: {
        line1:"Connect with the campaign",
        textbox: "Let Philip know you want to volunteer, request a yard sign or have access to the the newsletter",
        button: "Submit",
        confirmation: "Thanks for reaching out!",
        cards: [
            {
                line1: "Address",
                line2: "P.O. Box 21411 - Louisville KY 40221",
                class: "fa-map-marked-alt"
            },
            {
                line1: "Email",
                line2: "molestina2022@gmail.com",
                class: "fa-envelope"
            },
            {
                line1: "Phone",
                line2: "(502) 512-3646",
                class: "fa-mobile-alt"
            }
        ]
    },
    section6: {
        social_media:[
            {
                link:"https://www.facebook.com/profile.php?id=100067742075312",
                class:"fab fa-facebook-f"
            },
            {
                link:"https://twitter.com/ForMolestina",
                class:"fab fa-twitter"
            },
            {
                link:"https://www.instagram.com/philipformayor/",
                class:"fab fa-instagram"
            }
        ]
    }
}