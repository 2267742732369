<ngx-bootstrap-spinner>Processing...</ngx-bootstrap-spinner>
<!--Section1 Masthead-->
<header class="masthead" id="section1">
    <div class="container d-flex h-100 align-items-center">
        <div class="mx-auto text-center">
            <h1 class="mx-auto my-0 text-uppercase">{{config.section1.line1}}</h1>
            <h2 class="text-white-50 mx-auto mt-2 mb-5">{{config.section1.line2}}</h2>
            <a *ngIf="config.section1.showButtons" class="btn btn-primary js-scroll-trigger" [routerLink]="'/home'" fragment="section2">{{config.section1.line3}}</a>
        </div>
    </div>
</header>
<!--Section2-->
<section class="about-section text-center" id="section2">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 mx-auto">
                <h2 class="text-white mb-4">{{config.section2.line1}}</h2>
                <p class="text-white-50 mb-2" *ngFor="let subline of config.section2.line2.sublines">{{subline.text}}</p>
            </div>
        </div>
        <!--<img class="img-fluid" src="assets/img.redez.org/section.2.jpg" alt="..." /> -->
        <iframe src="../assets/img.philip4mayor.com/section2.mp4" width="100%" height="200" frameborder="0"></iframe>
    </div>
</section>
<!--Section3-->
<section class="projects-section bg-light" id="section3" style="padding-top: 2rem;">
    <div class="container">
        <!-- Featured Project Row 1-->
        <div class="row align-items-center no-gutters mb-4 mb-lg-5">
            <div class="col-xl-8 col-lg-7"><img class="img-fluid mb-3 mb-lg-0" src="{{config.section3.line1.img}}" alt="..." /></div>
            <div class="col-xl-4 col-lg-5">
                <div class="featured-text text-center text-lg-left">
                    <h4>{{config.section3.line1.title}}</h4>

                    <!-- <p class="text-black-50 mb-0" *ngFor="let subline of config.section3.line1.sublines">{{subline.text}}</p> -->
                    <ul>
                        <li class="text-black-50 mb-0" *ngFor="let subline of config.section3.line1.sublines">{{subline.text}}</li>
                    </ul>
                    
                </div>
            </div>
        </div>
        <!--dynamic project-->
        <div *ngFor="let project of config.section3.projects"  class="row justify-content-center no-gutters mb-5 mb-lg-0">
            <div class="col-lg-6">
                <img class="img-fluid" src="{{project.img}}" alt="..." />
            </div>
            <div class="col-lg-6 {{project.class}}">
                <div class="bg-black text-center h-100 project">
                    <div class="d-flex h-100">
                        <div class="project-text w-100 my-auto text-center">
                            <h4 class="text-white">{{project.title}}</h4>
                            <p class="mb-0 text-white-50">{{project.text}}</p>
                            <hr class="d-none d-lg-block mb-0 ml-0" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Featured Project Row 1-->
        <div *ngIf="config.section3.line2.show" class="row align-items-center no-gutters mb-4 mb-lg-5"  style="padding-top: 5rem;" id="section3_line2">
            <div class="col-xl-8 col-lg-7">
                <iframe seamless="seamless" scrolling="no" src="https://secure.anedot.com/philip-molestina-for-mayor/26f4069f-af57-48ab-ac34-b9c965c158d6?embed=true" width="360px" height="1000px" frameborder="0"></iframe>
            </div>
            <div class="col-xl-4 col-lg-5">
                <div class="featured-text text-center text-lg-left">
                    <h4>{{config.section3.line2.title}}</h4>
                    <p class="text-black-50 mb-0">{{config.section3.line2.text}}</p>
                </div>
            </div>
        </div>
    </div>
</section>

<!--Section4_5 Signup-->
<section class="signup-section" id="section4_5">
    <div class="container">
        <div class="row">
            <div class="col-md-10 col-lg-8 mx-auto text-center">
                <i class="far fa-paper-plane fa-2x mb-2 text-white"></i>
                <h2 class="text-white mb-5">{{config.section4_5.line1}}</h2>
                <form [formGroup]="subscribeForm" (ngSubmit)="onSubmit()" class="form-inline d-flex">
                    <input class="form-control flex-fill mr-0 mr-sm-2 mb-3 mb-sm-0" id="inputEmail" type="email" formControlName="email" placeholder="{{config.section4_5.textbox}}" />
                    <button class="btn btn-primary mx-auto" type="submit" [disabled]="!subscribeForm.valid">{{config.section4_5.button}}</button>
                </form>
                <div class="row align-items-center" style="padding-top: 5px;">
                    <div class="alert alert-success" role="alert" *ngIf="subscribed">
                        {{config.section4_5.confirmation}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--Section6 Contact-->
<section class="contact-section bg-black">
    <div class="container">
        <div class="row">
            <div  *ngFor="let card of config.section4_5.cards"  class="col-md-4 mb-3 mb-md-0">
                <div class="card py-4 h-100">
                    <div class="card-body text-center">
                        <i class="fas {{card.class}} text-primary mb-2"></i>
                        <h4 class="text-uppercase m-0">{{card.line1}}</h4>
                        <hr class="my-4" />
                        <div class="small text-black-50">{{card.line2}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="social d-flex justify-content-center">
            <a *ngFor="let ssm of config.section6.social_media" class="mx-2" href="{{ssm.link}}">
                <i class="{{ssm.class}} fa-2x"></i></a>
        </div>
    </div>
</section>
