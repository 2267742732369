import { Component, OnInit } from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import { HttpClient } from '@angular/common/http';

import { config } from '../config/grayscale/config';
import { NgxSpinnerService } from "ngx-bootstrap-spinner";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  config = config;
  subscribed = false;
  frameUrl="https://secure.anedot.com/philip-molestina-for-mayor/26f4069f-af57-48ab-ac34-b9c965c158d6?embed=true";
  subscribeForm = this.formBuilder.group({
    email:['',[Validators.required,Validators.pattern("[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?")]], 
    website : 'redez',
    type : 'subscription',
  });
  private REST_API_SERVER = "https://je3x5so4pg.execute-api.us-east-1.amazonaws.com/default/subscribeNewsletter";
  constructor(private formBuilder: FormBuilder,
    private httpClient: HttpClient,
    private spinner: NgxSpinnerService
  ){}

  ngOnInit(): void {
  }
  onSubmit(): void{
    this.spinner.show();
    const data = this.subscribeForm.value;
    console.log(data);
    this.httpClient.post(this.REST_API_SERVER, data).subscribe((data: any[])=>{
      this.subscribed = true;
      console.log(data);
      this.subscribeForm.reset();
      this.spinner.hide();
    })  
    
  }
}
