import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { OneComponent } from './one/one.component';
const routes: Routes = [
  {path: 'home', component: HomeComponent},
    {path: 'one', component: OneComponent},
    {path: '', redirectTo: '/home', pathMatch: 'full'},
];

@NgModule({
  imports: [
  //  RouterModule.forRoot(routes)
  RouterModule.forRoot(routes,{
    anchorScrolling: 'enabled'
  })
  ],
  
  exports: [RouterModule]
})
export class AppRoutingModule { }
